<template>
  <v-row
      no-gutters
      justify="center"
  >
    <v-col
        v-for="i in 5"
        :key="i"
        cols="12"
        xl="2"
        md="3"
        sm="4"
        xs="6"
    >
      <v-sheet
          :color="`white`"
          class="pa-3 ma-1"
      >
        <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            :types="{skeleton: 'card, actions'}"
            type="skeleton"
            transition="scale-transition"
        ></v-skeleton-loader>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({}),
};
</script>
