<template>
  <v-container class="">
    <h1 class="
        text-h4 text-md-h3
        primary--text
        text-uppercase text-center
        my-2 my-sm-6
      ">
      Mon espace numérique
    </h1>
    <v-row :justify="'end'" class="mr-5">
      <v-switch
          v-model="ChangeVue"
          inset
          :label="labelActif"
      />
    </v-row>
    <v-col
        class="mx-auto"
        justify-center
        cols="10"
        sm="8"
    >
      <v-autocomplete
          chips
          clearable
          deletable-chips
          multiple
          :items="books"
          item-text="matiere"
          label="Filtre par matiere"
          v-model="arrayFilter"
          v-if="books !== []"
      ></v-autocomplete>
    </v-col>
    <SkeletonLibrary v-if="!isLoad"/>
    <v-row v-if='noBooks'>Aucun ouvrage n'est disponible</v-row>
    <v-row
        no-gutters
        justify="center"
    >
      <v-col
          v-for="book in filteringBook"
          :key="book.refOffreDivalto"
          cols="6"
          xl="2"
          md="3"
          sm="4"
          xs="6"
          class="pa-1"
      >
        <v-card
            :to="'/'"
            :aria-label="book.nom"
            :name="book.nom"
            @click="readBook(book.refOffreDivalto)"
            class="mx-2 mb-6 "
            tile
            flat
            :img=book.imgCouverture
            style="aspect-ratio:2/3"
            contain
            elevation="10"
        >
        </v-card>
        <v-chip
            class="elevation-1 mx-2 mb-2 text-truncate primary--text"
            color="backgroundColor"
            label
            v-if="book.annee&&ChangeVue"
        >
          {{ book.annee }}
        </v-chip>
        <v-chip
            class="elevation-1 mx-2 mb-2 text-truncate primary--text"
            color="backgroundColor"
            label
            v-if="book.editeur&&ChangeVue"
        >
          {{ book.editeur }}
        </v-chip>
        <v-chip
            class="elevation-1 mx-2 mb-2 text-truncate primary--text"
            color="backgroundColor"
            label
            v-if="book.matiere&&ChangeVue"
        >
          {{ book.matiere }}
        </v-chip>
      </v-col>
      <!-- dialogue -->
      <v-dialog
          v-model="loading"
          persistent
          width="300"
      >
        <v-card
            color="primary"
            dark
        >
          <v-card-text>
            Redirection en cours
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0 mt-1"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import SkeletonLibrary from "./SkeletonLibrary.vue";
import BooksService from "/src/services/BooksService";

export default {
  components: {SkeletonLibrary},
  data: () => ({
    loading: false,
    isLoad: false,
    noBooks: false,
    // Data From API
    books: [],
    arrayFilter: [],
    ChangeVue: false,
  }),
  computed: {
    labelActif() {
      if (this.ChangeVue === true) {
        return "Affichage détaillé";
      } else {
        return "Affichage simplifié";
      }
    },
    filteringBook() {
      if (this.arrayFilter.length === 0) {
        return this.books;
      }
      return this.books.filter((book) =>
          this.arrayFilter.some((filter) => filter === book.matiere)
      );
    },
  },
  mounted() {
    if (!this.$store.state.user.nom) {
      this.$store.dispatch("user/updateUser");
    }
    BooksService.getBooks()
        .then(({data}) => {
          if (data.length === 0) {
            this.isLoad = true;
            this.noBooks = true;
          } else {
            this.isLoad = true;
            data.forEach((object) => {
              [object.ouvrage.matiere] = object.ouvrage.matiere;
              object.ouvrage.refOffreDivalto = object.refOffreDivalto;
              this.books.push(object.ouvrage);
            });
          }
        })
        .catch((error) => console.log(error));
  },
  methods: {
    readBook(refOffreDivalto) {
      const token = localStorage.getItem("TokenInfo");
      window.open(process.env.VUE_APP_URL + "/utilisateur/ouvrage/" + refOffreDivalto + "?access_token=" + JSON.parse(token).access_token, "_blank");
    },
  },
};
</script>
