<template>
  <v-card
    height="100%"
    color="backgroundColor"
  >
    <Library />
  </v-card>
</template>

<script>
import Library from "../components/DashboardVue/Library.vue";

export default {
  name: "Dashboard",

  components: {
    Library,
  },
};
</script>